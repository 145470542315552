<template>
    <b-col lg="6">
        <CCard>
            <CCardHeader v-b-toggle="'faq-id-' + data.id">
                <strong>{{ data.question[locale] }}</strong>
            </CCardHeader>
            <b-collapse :id="'faq-id-' + data.id">
                <CCardBody v-html="data.answer[locale]"></CCardBody>
            </b-collapse>
        </CCard>


    </b-col>
</template>

<script>
import mainServices from "../../services/mainServices";

export default {
    name: "faq-block",
    data: () => ({
        locale: 'de'
    }),
    props: ["data"],
    watch: {
        '$i18n.locale': function(newVal) {
            this.locale = newVal;
        }
    },
    mounted() {
        this.locale = mainServices.getLocale();
    }
};
</script>

<style scoped>

</style>
