<template>
    <div>
        <b-row class="justify-content-center">
            <b-col lg="6" xl="4" class="text-center">
                <h3>Hast du Fragen? Hier sind Antworten!</h3>
                <b-form @submit.prevent="onFiltered">
                    <b-form-group>
                        <b-form-input type="text" placeholder="Suche..." v-model="search" @keyup="onFiltered"/>
                    </b-form-group>
                </b-form>
            </b-col>
        </b-row>
        <b-row>
            <faq-block v-for="(item, index) in filteredItems" :key="index" :data="item"></faq-block>
        </b-row>
    </div>
</template>

<script>
import FaqBlock from "../components/faq/faq-block";
import faqService from "../services/faqService";
export default {
    name: "Faq",
    data: () => ({
        filteredItems: [],
        items: [],
        search: ''
    }),
    components: {FaqBlock},
    mounted() {
        faqService.list().then(response => {
            this.items = response.data;
            this.filteredItems = this.items;
        }).catch()
    },
    methods: {
        onFiltered(){
            const searchString = this.search.toLowerCase();
            if(searchString !== '') {
                this.filteredItems = this.items.filter(i => {
                    return i.question.de.toLowerCase().match(searchString) ||
                        i.question.en.toLowerCase().match(searchString) ||
                        i.answer.de.toLowerCase().match(searchString) ||
                        i.answer.en.toLowerCase().match(searchString) ||
                        i.tags.includes(searchString)
                })
            } else {
                this.filteredItems = this.items;
            }
        }
    }
};
</script>

<style scoped>

</style>
